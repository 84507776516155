import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
  type ErrorResponse,
} from '@remix-run/react'

import { getErrorMessage } from '#app/utils/misc.ts'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => JSX.Element | null

/**
 * This is a generic error handler that can be passed to remix's ErrorBoundary
 * it will handle errors with the default handler, but can be passed react components
 * to handle specific status codes. See /routes/$.tsx route for a example of handling 404 codes.
 */
export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.status} {error.data}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = error => <p>{getErrorMessage(error)}</p>,
}: {
  defaultStatusHandler?: StatusHandler
  statusHandlers?: Record<number, StatusHandler>
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
  const error = useRouteError()
  const params = useParams()

  if (typeof window !== 'undefined' && window?.faro?.api?.pushError) {
    window.faro.api.pushError(error)
  }

  if (typeof document !== 'undefined') {
    console.error(error)
  }

  return (
    <div className="flex min-h-screen flex-col bg-muted">
      <main className="flex grow flex-col justify-center">
        {isRouteErrorResponse(error)
          ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
              error,
              params,
            })
          : unexpectedErrorHandler(error)}
      </main>
    </div>
  )
}
